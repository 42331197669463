<template>
  <div class="index">
    <div class="lock_img">
      <img src="@/images/imageHL20/lock.png" alt width="100%" height="100%" />
    </div>
    <ul class="selection_bar">
      <li class="item" @click="explainNote">
        <img src="@/images/explain.png" alt />
        <span class="text">使用说明</span>
        <van-icon name="arrow" class="arrow_icon" />
      </li>
      <li class="item" @click="operation">
        <img src="@/images/operation_video.png" alt />
        <span class="text">操作视频</span>
        <van-icon name="arrow" class="arrow_icon" />
      </li>
      <li class="item" @click="mount">
        <img src="@/images/insta_info.png" alt />
        <span class="text">安装须知</span>
        <van-icon name="arrow" class="arrow_icon" />
      </li>
      <!-- <a :href="'tel:' + phonenumber" style="color: #272727" class="item">
        <img src="@/images/service.png" alt />
        <span class="text">拨打电话</span>
        <van-icon name="arrow" class="arrow_icon" />
      </a>-->
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phonenumber: "0755-86967915",
    };
  },
  methods: {
    explainNote() {
      // 打开Pdf文件
      window.open(
        "/hl20.pdf",
        "newwindow",
        "top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      );
    },

    // 安装
    mount() {
      this.$router.push({ path: "/install20" });
    },

    operation() {
      this.$router.push({ path: "/video20" });
    },
  },
};
</script>

<style scoped lang="stylus">
@import ('../../css/index.styl');
</style>