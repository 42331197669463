import Vue from "vue";
import VueRouter from "vue-router";

// HL10
import IndexOne from "../views/HL10/Index.vue";
import VideoOne from "../views/HL10/Video.vue";
import InstallLockOne from "../views/HL10/Install.vue"
import MeasureOne from "../views/HL10/Measure.vue"
import EndingOne from "../views/HL10/Ending.vue"

// HL20
import IndexTow from "../views/HL20/Index.vue";
import VideoTow from "../views/HL20/Video.vue";
import InstallLockTow from "../views/HL20/Install.vue"
import MeasureTow from "../views/HL20/Measure.vue"
import EndingTow from "../views/HL20/Ending.vue"

// HL30
import Index from "../views/HL30/Index.vue";
import Video from "../views/HL30/Video.vue";
import InstallLock from "../views/HL30/Install.vue"
import Measure from "../views/HL30/Measure.vue"
import Ending from "../views/HL30/Ending.vue"


Vue.use(VueRouter);

const routes = [
  {
    path: "/HL10",
    name: "IndexOne",
    component: IndexOne,
    meta:{
      title:"AI智能门锁，全新3D血管识别技术",
      icon: "/logo.ico"
    }
  },
  {
    path: "/video10",
    name: "VideoOne",
    component: VideoOne,
    meta:{
      title:"AI智能门锁，全新3D血管识别技术",
      icon: "/logo.ico"
    }
  },
  {
    path:"/install10",
    name:"InstallLockOne",
    component:InstallLockOne,
    meta:{
      title:"",
      icon: "/logo.ico"
    }
  },
  {
    path:"/measure10",
    name:"MeasureOne",
    component:MeasureOne,
    meta:{
      title:"",
      icon: "/logo.ico"
    }
  },
  {
    path:"/ending10",
    name:"EndingOne",
    component:EndingOne,
    meta:{
      title:"",
      icon: "/logo.ico"
    }
  },
  {
    path: "/HL20",
    name: "IndexTow",
    component: IndexTow,
    meta:{
      title:"AI智能门锁，全新3D血管识别技术",
      icon: "/logo.ico"
    }
  },
  {
    path: "/video20",
    name: "VideoTow",
    component: VideoTow,
    meta:{
      title:"AI智能门锁，全新3D血管识别技术",
      icon: "/logo.ico"
    }
  },
  {
    path:"/install20",
    name:"InstallLockTow",
    component:InstallLockTow,
    meta:{
      title:"",
      icon: "/logo.ico"
    }
  },
  {
    path:"/measure20",
    name:"MeasureTow",
    component:MeasureTow,
    meta:{
      title:"",
      icon: "/logo.ico"
    }
  },
  {
    path:"/ending20",
    name:"EndingTow",
    component:EndingTow,
    meta:{
      title:"",
      icon: "/logo.ico"
    }
  },
  {
    path: "/HL30",
    name: "Index",
    component: Index,
    meta:{
      title:"AI智能门锁，全新3D血管识别技术",
      icon: "/logo.ico"
    }
  },
  {
    path: "/video",
    name: "Video",
    component: Video,
    meta:{
      title:"AI智能门锁，全新3D血管识别技术",
      icon: "/logo.ico"
    }
  },
  {
    path:"/install",
    name:"InstallLock",
    component:InstallLock,
    meta:{
      title:"",
      icon: "/logo.ico"
    }
  },
  {
    path:"/measure",
    name:"Measure",
    component:Measure,
    meta:{
      title:"",
      icon: "/logo.ico"
    }
  },
  {
    path:"/ending",
    name:"Ending",
    component:Ending,
    meta:{
      title:"",
      icon: "/logo.ico"
    }
  }
];

const router = new VueRouter({
  routes,
  base: '/',
  mode: 'history',
    scrollBehavior (to, from, savedPosition) { // 重置页面滚动
    return { x: 0, y: 0 }
  }
});
router.beforeEach((to, from, next) => {
  Vue.nextTick(() => {
    const icon = document.getElementById("titleIcon")
    icon.href = to.meta.icon
  })
  next()
})

export default router;
