<template>
  <div class="ending">
    <div
      class="ending_status"
      :style="{ backgroundColor: isAccord ? '#0091FF' : '#999999' }"
    >
      <div class="desc" v-if="isAccord">
        <span>恭喜您!</span>
        <span>你的房门符合安装条件!</span>
      </div>
      <div class="desc" v-else>
        <span>很抱歉!</span>
        <span>你的房门不在安装范围内!</span>
      </div>
    </div>
    <div class="ending_data">
      <div class="title">
        <span
          :style="{ backgroundColor: isAccord ? '#0091FF' : '#999999' }"
        ></span>
        测量数据
      </div>
      <ul class="data">
        <li>导向片长度：240mm</li>
        <li>导向片宽度：24mm</li>
        <li>门厚：{{ endingValue }}mm</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ending",

  data() {
    return {
      isAccord: null,
      endingValue: 0,
    };
  },

  created() {
    this.endingValue = this.$route.query.value;
    if (!this.endingValue) return;
    if (this.endingValue >= 40) {
      this.isAccord = true;
    } else {
      this.isAccord = false;
    }
  },
};
</script>

<style scoped lang="stylus">
@import ('../../css/ending.styl');
</style>