<template>
  <div class="video" v-show="randerDom">
    <div v-for="item in videoUrl" :key="item.id" class="play_item">
      <div class="cover" @click="player(item)" v-show="item.isCover"></div>
      <video
        width="100%"
        controls
        class="video-box"
        :poster="item.cover"
        :id="item.id"
      >
        <source :src="item.video" type="video/mp4" />
      </video>
      <span>{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "video",

  data() {
    return {
      playId: 0,
      randerDom: false,
      videoUrl: [
        {
          id: 1,
          video: require("../../images/imageHL20/video1.mp4"),
          cover: require("../../images/imageHL20/cover2.jpg"),
          text: "指静脉知识科普",
          isCover: true,
        },
        {
          id: 2,
          video: require("../../images/imageHL20/video2.mp4"),
          video: require("../../images/imageHL20/normal_video2.mp4"),
          cover: require("../../images/imageHL20/cover1.png"),
          text: "指静脉智能锁的正确使用方式",
          isCover: true,
        },
        {
          id: 3,
          video: require("../../images/imageHL20/video3.mp4"),
          cover: require("../../images/imageHL20/cover3.png"),
          text: "万众一心齐抗疫——指静脉锁与您同在",
          isCover: true,
        },
      ],
    };
  },

  methods: {
    player(current) {
      this.playId = current.id;
      for (let item of this.videoUrl) {
        if (item.id == current.id) {
          if (document.getElementById(current.id).paused) {
            item.isCover = false;
            document.getElementById(current.id).play();
          } else if (document.getElementById(current.id).play()) {
            item.isCover = true;
            document.getElementById(current.id).pause();
          }
        } else {
          item.isCover = true;
          document.getElementById(item.id).pause();
        }
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.playId) {
      document.getElementById(this.playId).pause();
      this.randerDom = false;
    }
    next();
  },

  mounted() {
    setTimeout(() => {
      this.randerDom = true;
    }, 70);
  },
};
</script>

<style scoped lang="stylus">
@import ('../../css/video.styl');
</style>     