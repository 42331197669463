<template>
  <div class="install_lock">
    <div class="lock_chart">
      <img src="@/images/imageHL10/lock_chart.png" alt width="100%" />
    </div>
    <div class="lock_info">
      <van-cell title="导向片长度" value="240毫米" />
      <van-cell title="导向片宽度" value="24毫米" />
      <van-cell title="门厚" value="40~100毫米" />
    </div>
    <div class="lock_text">
      <div class="title">
        <span></span>
        安装须知
      </div>
      <ol class="content">
        <li>快速自测，判断是否支持安装</li>
        <li>1.标准锁体（即240*24锁体）可快速替换、安装。</li>
        <li>
          2.霸王、王力、盼盼、小帅等品牌锁体，需要对门进 行改造后才能安装。
        </li>
        <li>
          3.不支持安装天地钩，并且因为有脱落风险，门上原
          有的天地钩安装师傅将做拆除处理，请提前知晓。
        </li>
        <li>
          4.特殊门艺如铁艺门、栅栏门、双锁体门、防盗玻璃 门、隐藏锁等无法安装。
        </li>
        <li>5.门厚要在40-100mm之间。</li>
        <li>6.锁体中心距离前后门板要大余20mm。</li>
      </ol>
    </div>
    <div class="celiang">
      <img src="@/images/survey.png" alt @click="measure" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    // 立即测量
    measure() {
      this.$router.push({ path: "/measure10" });
    },
  },

  created() {},
};
</script>

<style scoped lang="stylus">
@import ('../../css/install.styl');
</style>