import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import Vant from "vant";
import { Button,Cell, CellGroup, Icon, Popup, Picker, Field } from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible/index.js'

Vue.config.productionTip = false;
Vue.use(Cell);
Vue.use(CellGroup)
Vue.use(Button);
Vue.use(Icon)
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Field)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
