<template>
  <div class="measure">
    <div class="lock_chart">
      <img src="@/images/imageHL20/lock_chart.png" alt width="100%" />
    </div>
    <div class="lock_info">
      <van-cell title="导向片长度" value="240毫米" />
      <van-cell title="导向片宽度" value="24毫米" />
      <van-field
        v-model="lockHeight"
        label="门厚"
        placeholder="请输入"
        input-align="right"
        type="number"
        :required="required"
        :error-message="errorMessage"
        error-message-align="right"
      />
    </div>
    <div class="commit">
      <van-button type="info" block @click="measureEnding">提 交</van-button>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lockHeight: null,
      showPicker: false,
      required: false,
      errorMessage: "",
    };
  },
  watch: {
    lockHeight(value) {
      if (value < 40 || value > 100) {
        this.required = true;
        this.errorMessage = "范围为40~100毫米";
      } else {
        this.required = false;
        this.errorMessage = "";
      }
    },
  },

  computed: {
    columns() {
      let value = [];
      for (let i = 1; i <= 100; i++) {
        value.push(i);
      }
      return value;
    },
  },

  methods: {
    measureEnding() {
      if (this.required == false) {
        this.$router.push({
          path: "/ending20",
          query: {
            value: this.lockHeight,
          },
        });
      } else {
        return;
      }
    },

    selectLockHeight() {
      this.showPicker = true;
    },

    onChange() {},

    onCancel() {
      this.showPicker = false;
      this.lockHeight = "";
    },

    onConfirm(value) {
      this.showPicker = false;
      this.lockHeight = value;
    },
  },
};
</script>

<style scoped lang="stylus">
.commit {
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 20px;
  width: calc(100% - 30px);
}
</style>

<style>
.measure /deep/ .van-button--info {
  background-color: #0091ff;
}
</style>